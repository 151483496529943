<template>
  <div>
    <div class="middle-group">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
   
};
</script>
<style lang="scss">
.middle-group {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: px2vh(84);
  z-index: 0;
}
</style>
