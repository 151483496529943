import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dangjian",
  },

  {
    path: '/dangjian', //党建
    name: 'dangjian',
    component: () =>
      import(/* webpackChunkName: "modules/cyyy" */ "../views/dangjian/index.vue"),
  },
  {
    path: '/fengmao', //党建
    name: 'fengmao',
    component: () =>
      import( "../views/fengmao/index.vue"),
  },
  {
    path: '/gongfu', //共富
    name: 'gongfu',
    component: () =>
      import( "../views/gongfu/index.vue"),
  },
  {
    path: '/chanye', // 产业
    name: 'chanye',
    component: () =>
      import("../views/chanye/index.vue"),
  },
  {
    path: '/ziyuan', // 资源
    name: 'ziyuan',
    component: () =>
      import("../views/ziyuan/index.vue"),
  },
  {
    path: '/zhili', // 治理
    name: 'zhili',
    component: () =>
      import("../views/zhili/index.vue"),
  },
  {
    path: '/zhihui', // 智慧
    name: 'zhihui',
    component: () =>
      import("../views/zhihui/index.vue"),
  },
  {
    path: '/wenhua', // 文化
    name: 'wenhua',
    component: () =>
      import("../views/wenhua/index.vue"),
  },
  {
    path: '/map', // map
    name: 'map',
    component: () =>
      import("../views/map/index.vue"),
  },
];

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes,
});

export default router;
