export const data_list = [
    {
        name: "党建",
        path: "/",
    },
    {
        name: "风貌",
        path: "/fengmao",
    },
    {
        name: "共富",
        path: "/gongfu",
    },
    {
        name: "产业",
        path: "/chanye",
    },
    {
        name: "资源",
        path: "/ziyuan",
    },
    {
        name: "治理",
        path: "/zhili",
    },
    {
        name: "智慧",
        path: "/zhihui",
    },
    {
        name: "文化",
        path: "/wenhua",
    },
]